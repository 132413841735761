module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Gabriel Duarte's Portfolio","short_name":"G. Duarte","start_url":"/?utm_source=web_app_manifest","background_color":"#e7e7e7","theme_color":"#343e46","display":"minimal-ui","icon":"src/assets/icons/icon512.png","lang":"en-US","description":"This is me :)"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
